<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Course</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="courseFilter"
            :options="courseOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => courseChanged(val)"
          />
        </b-col>
        <b-col
            v-if="categoryOptions.length > 0"
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label>Category</label>
          <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="categoryFilter"
              :options="categoryOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => categoryChanged(val)"
          />
        </b-col>
        <b-col
            v-if="subCategoryOptions.length > 0"
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
        >
          <label>Sub Category</label>
          <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="subCategoryFilter"
              :options="subCategoryOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:subCategoryFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  data(){
    return{
      lists: []
    }
  },
  methods:{
    categoryChanged(val){
      this.$emit('update:categoryFilter', val)
      this.$http.get('/sub-categories?'+'course_id='+this.courseFilter+'&category_id='+val)
          .then(response => {
            let that = this
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.subCategoryOptions.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
      this.$emit('update:categoryFilter', val)
    },
    courseChanged(val){
      this.$emit('update:courseFilter', val)
      this.categoryFilter = null
      this.$emit('update:categoryFilter', null)
      this.$http.get('/categories?'+'course_id='+val)
          .then(response => {
            let that = this
            this.categoryOptions.splice(0)
            this.lists.splice(0)
            this.lists = response.data.data.map(function(item){
              that.categoryOptions.push({
                label : item.label,
                value: item.value+""
              })
            })
          })
      this.$emit('update:courseFilter', val)
    },
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    planFilter: {
      type: [String, null],
      default: null,
    },
    lectureFilter: {
      type: [String, null],
      default: null,
    },
    courseFilter: {
      type: [String, null],
      default: null,
    },
    categoryFilter: {
      type: [String, null],
      default: null,
    },
    subCategoryFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    courseOptions: {
      type: Array,
      required: true,
    },
    categoryOptions: {
      type: Array,
      required: true,
    },
    subCategoryOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
